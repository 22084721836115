
import {
  defineComponent, ref, watch, computed,
} from '@vue/composition-api';
import { FCCart } from '@fc/angie-ui';
import { useCartContext } from '~/composables/useCartContext';
import { isMobileEffect } from '~/effects/matchMedia';

export default defineComponent({
  name: 'DefaultNavigationBar',
  components: {
    FCCart,
    CartDrawer: () => import('~/components/checkout/components/CartDrawer.vue'),
    MobileNavigationDrawer: () => import('~/components/checkout/components/MobileNavigationDrawer.vue'),
  },
  setup() {
    // @ts-expect-error
    const { $store } = useNuxtApp();
    const currentRoute = computed(() => $store.getters.currentRoute);
    const navigationData = computed(() => [
      {
        cta: 'Why FightCamp',
        elementName: 'NuxtLink',
        bindings: {
          to: '/why-choose-fightcamp',
        },
      },
      {
        cta: 'How it Works',
        elementName: 'NuxtLink',
        bindings: {
          to: '/how-it-works',
        },
      },
    ]);

    watch(currentRoute, (newVal) => {
      if (newVal) {
        isMenuOpen.value = false;
      }
    }, { deep: true });

    function getLinkName(elName: string) {
      return `${currentRoute.value.name}_NavigationBar_${elName}`;
    }

    const isMenuOpen = ref(false);
    function mobileMenuToggle() { isMenuOpen.value = !isMenuOpen.value; }

    function toggleDrawer() { $store.commit('toggleDrawer'); }

    const { cartqty } = useCartContext();
    const desktopNavigationData = computed(() => navigationData.value.filter((item) => item.cta !== 'Shop Packages'));

    const getMainLink = computed(() => {
      if (currentRoute.value.name === 'shop/products/fightcamp') {
        return 'shop';
      }
      return 'shop/products/fightcamp';
    });

    const isMobile = isMobileEffect();
    const showBuildPkgButton = computed(() => isMobile.value && currentRoute.value.name === 'shop');

    return {
      isMenuOpen,
      getLinkName,
      mobileMenuToggle,
      toggleDrawer,
      cartqty,
      desktopNavigationData,
      currentRoute,
      getMainLink,
      showBuildPkgButton,
    };
  },
});
